<template lang="pug">
  TiiaForm(:data="data" :rules="rules")
  //- pre {{ data }}
</template>

<script>
import TiiaForm from './TiiaForm'

export default {
  name: 'TiiaAdd',
  components: {
    TiiaForm
  },
  data () {
    return {
      data: {
        GroupId: undefined,
        EntityId: undefined,
        PicName: undefined,
        //- MaxQps: 10,
        //- GroupType: '5'
      },
      rules: {
        GroupId: [
          { required: true, message: 'Please input GroupId', trigger: 'blur' },
          //- { min: 3, max: 5, message: 'Length should be 3 to 5', trigger: 'blur' },
        ],
        GroupName: [{ required: true, message: 'Please input GroupName', trigger: 'blur' }],
        MaxCapacity: [{ required: true, message: 'Please input MaxCapacity', trigger: 'blur' }],
        //- region: [{ required: true, message: 'Please select Activity zone', trigger: 'change' }],
      }
    }
  },
  methods: {},
  //- beforeRouteLeave (to, from, next) {
  //-   const component = this.$refs['form'];
  //-   const { detail, detail_init } = component;
  //-   if (JSON.stringify(detail) === JSON.stringify(detail_init)) return next();
    
  //-   this.$Modal.confirm({
  //-     title: `提示`,
  //-     content: `内容已修改，是否保存`,
  //-     closable: true,
  //-     onOk: () => component.handleSubmit('form'),
  //-     onCancel: () => next(),
  //-   });
  //- }
  created() {
    //- setTimeout(() => {
    //-   this.$set(this.data, 'title', 'aa');
    //-   this.$set(this.data, 'content', 'bb');
    //- }, 1500)
  }
}
</script>

<style lang="less" scoped></style>